import { useRouter } from "next/router";
import { useState } from "react";
import { Box, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";

import {
  colors,
  customEvent,
  VStack,
  Image,
  LargeButton,
  LARGEL_BUTTON_TYPE,
  getCdnImageSrc,
} from "goi_common";

const HomeBannerSection = ({ path = "home" }: { path: "home" | "match_request" }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const router = useRouter();

  return (
    <Box background={colors.orange200}>
      <Box margin="48px 24px">
        <h2 className="subtitle_21_sb" css={{ marginBottom: "31px", color: colors.brown750 }}>
          미리 가입할 수도 있어요.
        </h2>

        <Tabs
          index={tabIndex}
          onChange={(index) => setTabIndex(index)}
          style={{ color: `${colors.gray500}`, borderColor: `${colors.brown350}` }}
        >
          <TabList
            _focus={{
              color: colors.black,
            }}
          >
            <Tab
              flex={1}
              fontSize="14px"
              color={colors.brown600}
              _selected={{
                color: colors.brown700,
                borderColor: colors.brown700,
                fontWeight: 700,
              }}
              onClick={() => {
                customEvent({
                  newGtm: {
                    path,
                    trigger_and_target: "banner_tab_0",
                  },
                });
              }}
            >
              만 원만 내고 할인 받기
            </Tab>

            <Tab
              flex={1}
              fontSize="14px"
              color={colors.brown600}
              _selected={{
                color: colors.brown700,
                borderColor: colors.brown700,
                fontWeight: 700,
              }}
              onClick={() => {
                customEvent({
                  newGtm: {
                    path,
                    trigger_and_target: "banner_tab_1",
                  },
                });
              }}
            >
              월 100원으로 준비하기
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel padding={"12px 0 0"}>
              <VStack alignItems="center">
                <Image
                  src={getCdnImageSrc("platform/home/banner-family-premium-2025.png")}
                  alt="banner-family-premium"
                  css={{
                    padding: "0 24px 0 ",
                  }}
                />

                <VStack gap={4} margin={"20px 0 0"}>
                  <div className="subtitle_21_eb brown700">지금 가입해도 148만 원 할인</div>
                  <div className="caption_12_sb brown600">
                    급하게 준비해야 하는 상황, 지금 계약만 해도 할인
                  </div>
                </VStack>

                <LargeButton
                  className="button_14_sb"
                  buttonType={LARGEL_BUTTON_TYPE.DEFAULT}
                  css={{
                    color: colors.white,
                    background: colors.brown550,
                    height: "34px",
                    marginTop: "24px",
                  }}
                  onClick={() => {
                    customEvent({
                      newGtm: {
                        path,
                        trigger_and_target: "banner_CTA-uv_promotion_service",
                      },
                    });
                    router.push("/promotion/service/가족장/premium-2025/");
                  }}
                >
                  장례 패키지 148만 원 할인 알아보기
                </LargeButton>
              </VStack>
            </TabPanel>

            <TabPanel padding={"12px 0 0"}>
              <VStack alignItems="center">
                <Image
                  width={200}
                  src={getCdnImageSrc("platform/home/banner-100-coin.png")}
                  alt="banner-100-coin"
                />

                <VStack gap={4} alignItems="center">
                  <div className="subtitle_21_eb brown700">매달 100원으로 장례 준비</div>
                  <div className="caption_12_sb brown600">
                    똑똑한 소비자를 위한 상조, 고이 프라임
                  </div>
                </VStack>

                <LargeButton
                  className="button_14_sb"
                  buttonType={LARGEL_BUTTON_TYPE.DEFAULT}
                  css={{
                    color: colors.white,
                    background: colors.brown550,
                    height: "34px",
                    marginTop: "24px",
                  }}
                  onClick={() => {
                    customEvent({
                      newGtm: {
                        path,
                        trigger_and_target: "banner_CTA-uv_prime",
                      },
                    });
                    router.push("https://prime.goifuneral.co.kr/");
                  }}
                >
                  100원으로 장례 준비 알아보기
                </LargeButton>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default HomeBannerSection;

import { Box, Button, Flex } from "@chakra-ui/react";
import { colors, customEvent, HStack, Spacing, VStack } from "goi_common";
import Slider from "react-slick";

import { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { HomeRenewalReviewCard } from "./HomeRenewalReviewCard";

export const bestReviews = {
  "사랑하는 가족을 모신 고객님들": [
    {
      id: 302,
      headline: "",
      good: `남편의 알아본 고이를 통해 전세권 장례 지도사님을 만나게 되었습니다. 병원에서 저녁 시간에 어머님이 돌아가시고 정신없이 병원 서류 마무리 하고 장례식장 예약 후 안치실로 가보니 이미 오셔서 기다리고 계시더군요. 여건 상 홀로 임종을 지켜야 했고, 홀로 안치를 시켜드려야 하는 상황에, 차가운 병원 직원들 사이에 따뜻하게 인사해 주시고 바로 도움을 주시기 시작했습니다. 어머니가 반듯하게 잘 안치 될 수 있도록 바로 도움을 주시는 모습이 인상적 이었습니다.
      `,
      score: 5,
      created_at: "2024-03-13T19:47:15+09:00",
      user: {
        nickname: "김OO",
      },
      serviceName: "가족장",
    },
    {
      id: 300,
      headline: "",
      good: `지방에 계신 아버지께서 고령이시긴 하셨지만 갑자기 돌아가셨단 연락을 받고 경황없이 급히이동하는 와중에 어머니때 인연이었던 장례지도사분이 다른 장례 진행 중이라 인터넷으로 급히 검색해서 닿은 고이상조입니다
      
      2년전 어머니를 보내드리며 치뤄본 장례이지만 부모님상은 차분하게 생각을 못하게 되네요
      
      어떤 경로로 연결이 되었는지 기억이 잘 나지않을 정도로 집중도 제대로 못 하며 보던 중이었던 거 같아요
      `,
      score: 5,
      created_at: "2024-03-12T12:36:15+09:00",
      user: {
        nickname: "전OO",
      },
      serviceName: "가족장",
    },
    {
      id: 295,
      headline: "",
      good: "갑작스럽게 치루게 된 장모님의 장례식이었습니다. 출장으로 멀리 나와있던 상황에 처제와 와이프에게 연락을 받고 막막한 상황에서 '고이'와 상담을 받게되었습니다. 빠른 연락 덕분에 상담도 빠르게 받게되었고, 절차 및 금액에 대한 부분도 가감없이 바로바로 설명해주셨습니다. 덕분에 정말 걱정없이 너무 편안히 장모님을 잘 보내드리게 되었습니다.",
      score: 5,
      created_at: "2024-03-09T09:41:15+09:00",
      user: {
        nickname: "차OO",
      },
      serviceName: "가족장",
    },
    {
      id: 270,
      headline: "",
      good: "저희 아버지께서 말기암 환자이셨고 이에 따라 시간이 얼마 남지 않아 아버지와의 작별인사를 어떻게 해야 할지 참 고민이 많았습니다. 늘 아침에 일어나면 생각나는 것이 장례의 일이었으니까요. 믿을 수 있는 상조의 정보는 없고 장례식에서의 소개된 상조 회사들은 가격들이 만만치 않았습니다. 그러던 아버지를 케어하던 아침에 고이라는 상조 회사를 알게 되었습니다. 임종하시기 전부터 여러 장례의 모든 일들을 사전에 수고해주시고 상담해주신 고이 김지민 과장님",
      score: 5,
      created_at: "2024-02-05T19:34:15+09:00",
      user: {
        nickname: "배OO",
      },
      serviceName: "가족장",
    },
  ],

  "종교 장례를 경험한 고객님들": [
    {
      id: 250,
      headline: "",
      good: "저희는 기독교장례를 전문으로 하시는 팀장님께서 맡아주셨었는데, 복장부터 다른 상조회사보다 고급지고 단정하셔서 신뢰가갔고, 모든 장례절차에서 항상 꼼꼼한설명과 충분한 시간을 주시면서 유가족들이 충분히 애도할수있도록 해주셨습니다. 덕분에 고인을 잘모시고 가족들도 일상으로 돌아갈수있는 큰힘을 얻었습니다. 다시한번 너무 감사드리며, 이 감사함과 배려는 오래도록 간직하고 기억하겠습니다. 비용부터 진행과정과 안내 모든것이 매끄럽고 합리적이었다고 생각합니다.",
      score: 5,
      created_at: "2024-01-19T16:05+09:00",
      user: {
        nickname: "홍OO",
      },
      serviceName: "가족장",
    },
  ],
  "해외 거주민 고객님들": [
    {
      id: 273,
      headline: "",
      good: "저는 무남독녀이고 미국에 거주하고 있어서 모친상을 당하고 어쩔줄 몰랐어요. 혹시 몰라 마국에서 상담을 한 적 있어서 고이에 연락을 드렸고 , 박종찬 팀장님이 강남 성모 병원으로 밤 11시에 도착하면서 장례 일정이 시작되었습니다. 제가 해외 거주자라서 인증절차가 복잡했는데, 박종찬 팀장님이 새벽 1시가 넘도록 이리저리 애 많이 쓰셨어요. 고이가 TV 광고에서 못보던 회사라 제가 강남 성모에 슬쩍 물어봤더니 , 한 달에 여러번 여기서 장례식 진행하는 회사라는 소리 듣고 믿음이 막 가더라구요. 항상 옵션을 2개 주시고 , 가능하면 첫번째 원하는 옵션을 맞춰주시려고 애 써주셔서 감사했습니다. 저는 어제 모든 장례후 일정 마치고, 귀국했어요. 장례 절차 모든게 흡족하고., 품격 있었습니다. 감사합니다.",
      score: 5,
      created_at: "2024-02-10T14:07:15+09:00",
      user: {
        nickname: "김OO",
      },
      serviceName: "가족장",
    },

    {
      id: 263,
      headline: "",
      good: "처가가 중국 교포이셔서 갑자기 상을 당하고 많이 당황스러워하셨는데, 전세권 파트너님께서 너무 친절하게 잘 안내해주셔서, 가족과 친지분들 모두 편안한 마음으로 할머님을 고이 보내드릴 수 있었습니다. 특히 장례라는 것이 감정적인 부분이 너무 중요한데 그런 디테일한 부분도 놓치지 않고 세심하게 관리해주셔서 너무 감사드립니다.",
      score: 5,
      created_at: "2024-01-31T22:17:15+09:00",
      user: {
        nickname: "박OO",
      },
      serviceName: "가족장",
    },

    {
      id: 11,
      headline:
        "뭘 어떻게 해야 할지 모르는 상황에 제가 사는 아파트까지 오셔서 저를 태우고 장례식장, 중국 영사관까지 데려다주면서 진행해 주셨습니다",
      good: "아버님이 중국 분이셔서 화장 절차나 서류 준비 등 필요한 부분을 전문적으로 잘 안내해 주었습니다. 아버님이 공항 입국 후 하루 만에 사망하셔서 당황스럽고 뭘 어떻게 해야 할지 모르는 상황에 제가 사는 아파트까지 오셔서 저를 태우고 장례식장, 중국 영사관까지 데려다주면서 진행해 주셨습니다. 지도사님 아니면 큰일 날뻔 했습니다. 전문적인 지식을 갖고 있는 고이 특히 지도사님의 배려로 무사히 장례를 치를 수 있어서 좋았습니다.",
      score: 5,
      created_at: "2022-03-10T09:00:00+09:00",
      user: {
        nickname: "김OO",
      },
      serviceName: "가족장",
    },
    {
      id: 162,
      headline:
        "결코 브랜드 상조패키지에 뒤지지 않는 퀄리티의 서비스여서 정말 많은 칭찬과 인사를 받을 수 있었습니다.",
      good: `
      형제 자매가 많았지만, 대부분 외국에서 오래 살다 오셨고, 연세들이 많으셔서 막내 며느리인 제가 모든 준비를 해야했는데,
      별도로 상조에 가입이 되어 있었음에도 불구하고 수많은 비교 검색 끝에 [고이]서비스를 알게 되어 도움을 받아
      아버님 가시는길에 편히 모실 수 있었습니다.
      계성희 장례지도사님, 음식접객 관리사분들 모두 정말 친절하시고 진심을 담아 열심히 해주신 덕분에
      저희 식구들은 정말 큰 사랑만 주고 가신 아버님을 추모하는 시간을 충분히 가질 수 있었습니다.
      수많은 장례업체와 첫 통화시 받았던 느낌이 선택을 좌우하는 만큼 최초로 통화했던 담당자분께 받았던 신뢰감과 따뜻함은
      결코 믿음을 배신하지 않았습니다.
      장례지도사님께서는 많은 자녀들 사위들 며느리들 모두 아버님께 충분히 인사드릴 수 있도록 순간순간 배려해주신다는 점이 정말 느껴졌고,
      특히 아버님 입관시에 거의 막바지쯤 아버님사랑을 가장 많이 받았던 막내아들인 저희 남편이 손목에 차고 있던 염주를 함께 넣어드리고 싶다고 하자
      솔직히, 관속이나 수의깃에 넣어 주실 줄 알았는데,, 꼼꼼히 마무리 하셨던 손목부분의 수의를 모두 풀고 아버님 손목에 직접 채워주시고 다시 수습해서 마무리해주신 모습에
      너무 큰 감동을 받아 다른 사람들에게도 두고 두고 이야기 하였답니다.
      저희가 이용하지 않은 서비스에 대한 부분은 정확하게 공제해 주시고, 오히려 서비스로 지급 받았던 부분들도 많았고, 굉장히 합리적인 비용이었지만
      결코 브랜드 상조패키지에 뒤지지 않는 퀄리티의 서비스여서 정말 많은 칭찬과 인사를 받을 수 있었습니다.
      겉만 번지르르하고 불필요하거나 거품이 많이 포함된 장례서비스들에 지쳐 힘들때 때마침 늦지 않게 나타나준 [고이] 업체는
      아버님께서 가시면서 자식들을 도와주신것이라고 생각이 들 만큼 감사했습니다.
      많은 분들이 함께 하실 수 있었으면 좋겠다고 생각했습니다.`,
      score: 5,
      created_at: "2023-09-28T17:01:53.836562+09:00",
      user: {
        nickname: "박OO",
      },
      serviceName: "가족장",
    },
  ],
  "가입된 상조가 있으셨던 고객님들": [
    {
      id: 293,
      headline:
        "언니들이 유명한 타 회사 서비스에 가입되어 있는데 굳이 고이를 선택했냐며 의문스러워 했었는데 장례기간 내내 발인이후에도 지도사님께 감사하단 말씀을 내내 했어요.",
      good: `전ㅇㅇ 지도사님,
      정말 감사합니다. 언니들이 유명한 타 회사 서비스에 가입되어 있는데 굳이 고이를 선택했냐며 의문스러워 했었는데 장례기간 내내 발인이후에도 지도사님께 감사하단 말씀을 내내 했어요. 여러 입관식을 가봤지만 이번처럼 정성스런 경우는 처음봤다면서요. 장례시간내내 차분하게 안내해 주시고 진행을 리드해주셔서 정말 편안하게 아빠를 모실수 있었습니다.
      특히 장례가 발생하기 전 걱정된 마음으로 이것저것 문의드렸을때 준비할 사항을 친절하게 안내해 주신 한ㅇㅇ 팀장님께도 감사드립니다.`,
      score: 5,
      created_at: "2024-02-28T16:35:15+09:00",
      user: {
        nickname: "정OO",
      },
      serviceName: "일반장",
    },
    {
      id: 182,
      headline: "",
      good: `선불상조가 있음에도 불구하고 지불금액대비 더 저렴한 후불상조랑 차이가 없어서 이용했는데요.
      기본항목에서 더하기함에 있어서도 행여 이용지역 금액이 더 저렴하면 상세하게 설명해주고 불필요한 부분은 생각해보라고도 해주시고 같은질문을 여러번받게되는 상황에서도 대충이라는게 없이 잘 설명해주셔서 감사했습니다.
      가족친지들 모두 너무 감사했다고 어느상조냐며 칭찬일색이어서 괜히 저또한 뿌듯했습니다.
      다시한번 감사드립니다`,
      score: 5,
      created_at: "2023-10-30T19:56:56.668447+09:00",
      user: {
        nickname: "현OO",
      },
      serviceName: "가족장",
    },
    {
      id: 229,
      headline: "",
      good: "원래 회사에 협약되어 있었지만 검색해보다 찾아본 회사가 고이상조였어요 합리적인 가격과 신속한 진행이 너무 만족스러웠습니다 강지도사님은 상황에따라 최상의 결정을 해주셔서 장례 끝나고도 너무 고마웠습니다 상을 처음 치뤄 보지만 개인마다 상황도 다르고 원하는게 다를건데 모든 진행과정이 만족스러웠습니다.처음에 상담해주신 한수경 상담사님도 너무 감사드립니다",
      score: 5,
      created_at: "2024-01-01T13:48:00+09:00",
      user: {
        nickname: "윤OO",
      },
      serviceName: "가족장",
    },
  ],
};

const REVIEW_TABS = [
  {
    name: "사랑하는 가족을 모신 고객님들",
    bgColor: colors.warmGray450,
    color: colors.warmGray800,
    bgImage: "review_background_1",
    subtitleColor: colors.warmGray650,
    contentColor: colors.warmGray850,
    editColor: colors.warmGray800,
  },
  {
    name: "종교 장례를 경험한 고객님들",
    bgColor: colors.orange150,
    color: colors.brown350,
    bgImage: "review_background_2",
    subtitleColor: colors.orange550,
    contentColor: colors.brown800,
    editColor: colors.warmGray800,
  },
  {
    name: "해외 거주민 고객님들",
    bgColor: colors.brown800,
    color: colors.brown150,
    bgImage: "review_background_3",
    subtitleColor: colors.brown200,
    contentColor: colors.brown50,
    editColor: colors.warmGray150,
  },
  {
    name: "가입된 상조가 있으셨던 고객님들",
    bgColor: "#BFD0EB",
    color: colors.blue650,
    bgImage: "review_background_4",
    subtitleColor: colors.blue550,
    contentColor: colors.blue850,
    editColor: colors.blue350,
  },
];

function HomeSelectedReviews() {
  const [tab, setTab] = useState(REVIEW_TABS[0]);

  const currentReview = useMemo(() => {
    return bestReviews[tab.name as keyof typeof bestReviews];
  }, [tab, bestReviews]);

  const initialSlide = 0;
  const [slider, setSlider] = useState<Slider | null>();
  const [beforeCount, setBeforeCount] = useState<number>(initialSlide);
  return (
    <VStack gap="4px" width="100%">
      <Spacing gap={6} />
      <Flex width="100%" flexWrap="wrap" gap="8px">
        {REVIEW_TABS.map((res) => {
          return (
            <Button
              key={res + "btn"}
              size="sm"
              border="1px solid #CCC"
              borderRadius={20}
              onClick={() => {
                setTab(res);
                slider?.slickGoTo(0);
                customEvent({
                  newGtm: {
                    path: "home",
                    trigger_and_target: "review-button_category",
                    text: res.name,
                  },
                });
              }}
              color={res.name === tab.name ? colors.white : colors.warmGray700}
              bg={res.name === tab.name ? colors.warmGray700 : colors.white}
              _focus={{ bgColor: colors.warmGray700 }}
              width="fit-content"
              fontSize={12}
            >
              # {res.name}
            </Button>
          );
        })}
      </Flex>
      <HStack justifyContent="space-between" margin="40px 0 -10px" alignItems="center">
        <Box className="subtitle_14_sb" style={{ color: tab.subtitleColor }}>
          # {tab.name}
        </Box>
        <HStack gap={12}>
          {currentReview.map((res, i) => {
            return (
              <Box
                style={{
                  width: "8px",
                  height: "8px",
                  background: i === beforeCount ? colors.warmGray800 : colors.gray300,
                  borderRadius: 30,
                }}
                key={i + "reviewnum"}
              />
            );
          })}
        </HStack>
      </HStack>

      <VStack
        maxWidth="100vw"
        width="100% !important"
        gap={60}
        margin="0 0 10px"
        position="relative"
      >
        <Slide
          initialSlide={initialSlide}
          beforeChange={(_, next) => {
            setBeforeCount(next);
          }}
          arrows={false}
          infinite={true}
          ref={(s) => setSlider(s)}
          autoplay={true}
          autoplaySpeed={10000}
        >
          {currentReview?.map((r, index) => (
            <HomeRenewalReviewCard
              key={r.id + "reviewslick"}
              bgColor={tab.bgColor}
              color={tab.color}
              bgImage={tab.bgImage}
              contentColor={tab.contentColor}
              editColor={tab.editColor}
              {...r}
              event={{
                path: "home",
                trigger_and_target: "review_detail",
              }}
            />
          ))}
        </Slide>
      </VStack>
    </VStack>
  );
}

export default HomeSelectedReviews;

const Slide = styled(Slider)`
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    padding: 0 8px;
  }
  .slick-list {
    margin: 0 -8px;
  }
`;
